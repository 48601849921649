<template>
    <painel titulo="Soluções" icone="lightbulb" :refreshFunction="obterTodos" v-if="mostrarListagem">
        <div class="container text-center mt-5 mb-5">
            <div class="table-responsive my-5">
                <listagem-solucoes
                    :campos="campos"
                    :data="solucoes"
                    :inativos="inativos"
                    @onExcluir="excluir"
                    @onEditar="editar"
                    @onAlterarSituacao="situacao"
                    @onInserir="inserir"
                    @onModal="historico"
                    @onVisualizar="visualizar"
                    @onImagens="imagens"
                    @mostrarInativos="mostrarInativos"
                ></listagem-solucoes>
                <solucao-historico v-if="modal" :modal="modal" :objeto="solucaoId" @closeModal="modal = false" />
                <solucao-modal-alterar-situacao
                    v-if="alterarSituacao"
                    :alterarSituacao="alterarSituacao"
                    @obterTodos="obterTodos()"
                    :objeto="objeto"
                    @closeModal="alterarSituacao = false"
                />
            </div>
        </div>
    </painel>
    <router-view></router-view>
</template>

<script>
import ListagemSolucoes from './ListagemSolucoes.vue';
import Services from './service';
import SolucaoHistorico from './SolucaoModalHistorico.vue';
import SolucaoModalAlterarSituacao from './SolucaoModalAlterarSituacao.vue';
export default {
    name: 'Soluções',
    components: {
        ListagemSolucoes,
        SolucaoHistorico,
        SolucaoModalAlterarSituacao,
    },
    data() {
        return {
            inativos: true,
            data: [],
            solucoes: [],
            modal: false,
            situacaoFalse: false,
            alterarSituacao: false,
            solucaoId: null,
            campos: [
                { campo: 'title', titulo: 'Título' },
                { campo: 'tipoServico.title', titulo: 'Tipo de Serviço' },
                { campo: 'origemContratacao.title', titulo: 'Origem de Contratação' },
                { campoHora: 'cargaHoraria', titulo: 'Carga Horária' },
                { campoValor: 'valorTotal', titulo: 'Preço Cliente' },
                { campoPreco: 'custo', titulo: 'Custo' },
                { campo: 'subArea', campoArray: 'subAreaArray', titulo: 'Área/Sub-Área' },
                { campoModified: 'modified', titulo: 'Modificado' },
                { campo: 'modifiedBy', titulo: 'Modificado por' },
                { campoActive: 'active', titulo: 'Status' },
                { campoIntegracao: 'integracaoSas', titulo: 'SAS' },
            ],
        };
    },
    created() {
        this.obterTodos();
    },
    computed: {
        mostrarListagem() {
            return this.$route.name === 'Solucoes';
        },
    },

    methods: {
        obterTodos() {
            if (this.$route.query.view !== 'vitrine') {
                this.$store.dispatch('addRequest');
                Services.obterTodos(this.inativos ? null : true).then((response) => {
                    if (response?.success) {
                        this.data = response.data;
                        this.solucoes = this.data;
                    }
                    this.data.forEach((solucao) => {
                        solucao.subAreaArray = solucao.subAreas.map((subArea) =>
                            subArea.subArea.area.title == null ? 'Não tem Area' : subArea.subArea.area.title + ' / ' + subArea.subArea.title
                        );
                    });
                    this.$store.dispatch('removeRequest');
                });
            }
        },
        mostrarInativos(inativo) {
            this.inativos = inativo;
        },
        excluir(solucao) {
            this.$store.dispatch('addRequest');
            Services.excluir(solucao.id).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão de Solução',
                        detail: 'Solução excluída com sucesso',
                        life: 3000,
                    });
                    this.obterTodos();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Exclusão de Solução', detail: response.errors[0], life: 3000, group: 'dialog' });
                }
                this.$store.dispatch('removeRequest');
            });
        },
        inserir() {
            this.$router.push({
                name: `Solucao_Inserir`,
            });
        },
        editar(solucao) {
            this.$router.push({
                name: `Solucao_Atualizar`,
                params: {
                    id: solucao.id,
                },
            });
        },
        situacao(solucao) {
            this.objeto = solucao;
            this.alterarSituacao = true;
        },

        historico(solucao) {
            this.solucaoId = solucao.id;
            this.modal = true;
        },
        visualizar(solucao) {
            this.$router.push({
                name: `Solucao_Visualizar`,
                params: {
                    id: solucao.id,
                },
            });
        },
        imagens(solucao) {
            this.$router.push({
                name: `Solucao_Imagens`,
                params: {
                    id: solucao.id,
                },
            });
        },
    },
    watch: {
        inativos() {
            if (!this.inativos) {
                (this.solucoes = []),
                    this.data.forEach((solucao) => {
                        if (solucao.active == true) {
                            this.solucoes.push(solucao);
                        }
                    });
            } else {
                this.solucoes = this.data;
            }
        },
        mostrarListagem() {
            if (this.mostrarListagem && this.data.length === 0) {
                this.obterTodos();
            }
        },
        $route(to) {
            if (to.name === 'Solucoes') {
                if (this.$store.getters.atualizar) {
                    this.obterTodos();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
