import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}/solucoes`;

export default {
    obterTodos(active) {
        active == null ? active = '' : active
        return axiosJwt.get(`${urlBase}?active=${active}`);
    },
    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
    excluir(id) {
        return axiosJwt.delete(`${urlBase}/${id}`);
    },
    inserir(solucao) {
        return axiosJwt.post(`${urlBase}`, solucao);
    },
    atualizar(id, solucao) {
        return axiosJwt.put(`${urlBase}/${id}`, solucao);
    },
    obterTiposExecucao() {
        return axiosJwt.get(`${urlBase}/tiposexecucao`);
    },
    obterAlteracoes(id) {
        return axiosJwt.get(`${urlBase}/${id}/alteracoes`);
    },
    obterAlteracoesInativadas(id) {
        return axiosJwt.get(`${urlBase}/${id}/inativacoes`);
    },
    obterUnidadeOrganizacional() {
        return axiosJwt.get(`${api}/sas/unidades`)
    },
    obterMotivosEdicao() {
        return axiosJwt.get(`${urlBase}/motivosedicao`);
    },
    alterarSituacao(id, solucao) {
        return axiosJwt.patch(`${urlBase}/${id}`, solucao);
    },
    obterMotivoInativacao() {
        return axiosJwt.get(`${urlBase}/motivosinativacao`);
    },
    obterHistoricoIntegracoesSas(id) {
        return axiosJwt.get(`${urlBase}/${id}/integracoes`);
    }
};
