<template>
    <div>
        <Dialog v-model:visible="displayModal" :breakpoints="{ '640px': '60vw' }" :style="{ width: '400px' }" :modal="true" dismissableMask>
            <div>
                <div class="card">
                    <Steps :model="items" :readonly="true" :exact="false" />
                    <div v-if="index == 0" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Selecione Motivo da Inativação</h4>
                        <Dropdown
                            class="mt-7 flex justify-content-center"
                            v-model="motivoInativacao"
                            :options="motivosInativacao"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione uma opção"
                            @input="v$.motivoInativacao.$touch()"
                        />
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="motivoInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Você precisa selecionar algum!</Message
                        >
                    </div>
                    <div v-if="index == 1" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Justifique</h4>
                        <div class="my-4 flex align-items-center justify-content-center">
                            <Textarea v-model="justificativaInativacao" @input="v$.justificativaInativacao.$touch()" :autoResize="true" rows="4" cols="80" />
                        </div>
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="justificativaInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Insira a justificativa!</Message
                        >
                    </div>
                </div>
                <div class="flex justify-content-between flex-wrap card-container">
                    <Button :disabled="index < 1" label="Voltar" icon="pi pi-arrow-left" class="mr-2 p-button-secondary" @click="prevPage"></Button>
                    <Button v-if="index < 1" label="Proximo" icon="pi pi-arrow-right" iconPos="right" @click="nextPage"></Button>
                    <Button v-if="index == 1" label="Salvar" icon="pi pi-check-circle" @click="salvar"></Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Service from './service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    props: {
        modal: {
            type: Boolean,
        },
        objeto: {
            type: Object,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            justificativaInativacao: null,
            motivoInativacao: null,
            index: null,
            options: [
                {
                    label: 'Sim',
                    value: true,
                },
                {
                    label: 'Não',
                    value: false,
                },
            ],
            items: [
                {
                    label: null,
                    to: '',
                },
                {
                    label: null,
                    to: '',
                },
            ],
            displayModal: false,
            position: 'center',
            motivosInativacao: [],
            motivoInvalid: null,
            justificativaInvalid: null,
        };
    },
    mounted() {
        this.obterMotivosDesativacao();
        if (this.modal) {
            this.index = 0;
            this.items[this.index].to = this.$router.currentRoute.value.fullPath;
            this.displayModal = true;
        }
    },
    validations() {
        return {
            justificativaInativacao: { required },
            motivoInativacao: { required },
        };
    },
    methods: {
        prevPage() {
            if (this.index > 0) {
                this.items[this.index].to = null;
                this.index--;
            }
        },
        nextPage() {
            if (this.index < 1) {
                if (this.v$.motivoInativacao.$invalid && this.index == 1) {
                    this.motivoInvalid = true;
                } else {
                    this.index++;
                    this.items[this.index].to = this.$router.currentRoute.value.fullPath;
                }
            }
        },
        salvar() {
            if (this.v$.justificativaInativacao.$invalid) {
                this.justificativaInvalid = true;
            } else {
                let solucaoDto = {
                    justificativaInativacao: this.justificativaInativacao,
                    motivoInativacao: this.motivoInativacao,
                };
                this.$emit('alterar', solucaoDto);
            }
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        obterMotivosDesativacao() {
            this.$store.dispatch('addRequest');
            Service.obterMotivoInativacao().then((response) => {
                if (response?.success) {
                    var obj = response.data;
                    var array = Object.keys(obj).map(function (key) {
                        return {
                            label: obj[key],
                            value: key,
                        };
                    });
                    this.motivosInativacao = array;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
        motivoInativacao() {
            this.motivoInvalid = false;
        },
        justificativaInativacao() {
            this.justificativaInvalid = false;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-steps-number) {
    margin-bottom: 10px;
    min-height: 50px;
    width: 50px;
}
</style>
