<template>
    <div>
        <Dialog
            header="Selecione a nova situação da Solucao"
            v-model:visible="displayModal"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :modal="true"
            dismissableMask
        >
            <SelectButton
                class="mt-5 flex justify-content-center"
                v-model="solucao.active"
                :options="options"
                optionLabel="label"
                optionValue="value"
                aria-labelledby="single"
            />
            <Button class="mt-5 flex justify-content-center" label="salvar" icon="pi pi-check-circle" @click="salvar"></Button>
        </Dialog>
    </div>
    <solucao-modal-confirmacao v-if="confirmar" :modal="confirmar" :objeto="objeto" @alterar="alterar" />
</template>

<script>
import Service from './service';
import SolucaoModalConfirmacao from './SolucaoModalConfirmacao.vue';

export default {
    components: {
        SolucaoModalConfirmacao,
    },
    props: {
        alterarSituacao: {
            type: Boolean,
        },
        objeto: {
            type: Object,
        },
    },
    data() {
        return {
            confirmar: false,
            displayModal: false,
            position: 'center',
            solucao: {
                active: this.objeto.active,
                justificativaInativacao: null,
                motivoInativacao: null,
            },
            options: [
                {
                    label: 'Ativo',
                    value: true,
                },
                {
                    label: 'Inativo',
                    value: false,
                },
            ],
        };
    },
    mounted() {
        if (this.alterarSituacao) {
            this.displayModal = true;
        }
    },

    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        alterar(objeto) {
      
            if (objeto) {
                this.solucao.justificativaInativacao = objeto.justificativaInativacao
                this.solucao.motivoInativacao = objeto.motivoInativacao
            }

            this.$store.dispatch('addRequest');
            Service.alterarSituacao(this.objeto.id, this.solucao).then(() => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Alterar Situação',
                    detail: 'Situação alterada com sucesso',
                    life: 3000,
                });

                this.$emit('obterTodos');
                this.$emit('closeModal');
                this.$store.dispatch('removeRequest');
            });
        },
        salvar() {
            if (!this.solucao.active) {
                this.confirmar = true;
            } else {
                this.alterar();
            }
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>
