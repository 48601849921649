<template>
    <tabela headerStyle="width: 3em" id="listagemSolucoes" :salvarEstado="false" :pesquisaComDelay="true" :data="data">
        <template #checkbox>
            <Checkbox name="Mostrar Inativos" class="ml-3 mb-2 align-content-start lg" :modelValue="inativos" @click="mostrarInativos" :binary="true" />
            <label for="binary"> Mostrar Inativas </label>
        </template>
        <template #botoes>
            <btn-inserir @onInserir="inserir"></btn-inserir>
        </template>
        <template #conteudo>
            <solucao-modal-historico-integracao v-if="modalIntegracao" :modal="modalIntegracao" :objeto="solucaoId" @closeModal="modalIntegracao = false" />
            <Column>
                <template class="grid row align-items-center" #body="slotProps">
                    <Button
                        type="button"
                        title="Opções"
                        icon="pi pi-cog"
                        class="p-button-text p-button-lg"
                        @click="toggle($event, slotProps.data)"
                        aria-haspopup="true"
                        aria-controls="overlay_menu"
                    />
                    <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
                </template>
            </Column>
            <template v-for="(campo, index) in campos" :key="index">
                <Column
                    :header="campo.titulo"
                    :field="campo.campo"
                    :sortable="true"
                    v-if="!campo.campoArray && !campo.campoHora && !campo.campoPreco && !campo.campoValor && !campo.campoActive && !campo.campoIntegracao && !campo.campoModified"
                ></Column>
                <Column :header="campo.titulo" :sortable="true" :field="campo.campoIntegracao" v-if="campo.campoIntegracao">
                    <template #body="slotProps">
                        <Button
                            @click="historicoIntegracao(slotProps.data.id)"
                            class="p-button-text"
                            style="font-size: 1.3rem; color: var(--green-400)"
                            v-if="slotProps.data.integracaoSas"
                        >
                            <font-awesome-icon icon="check-circle" title="Detalhar Integrações" />
                        </Button>
                        <Button @click="historicoIntegracao(slotProps.data.id)" class="p-button-text" style="font-size: 1.3rem; color: #d32f2f" v-else>
                            <font-awesome-icon icon="exclamation-circle" title="Detalhar Integrações" />
                        </Button>
                    </template>
                </Column>
                 <Column v-if="campo.campoModified" :field="campo.campoModified" :header="campo.titulo" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.modified, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column :sortable="true" v-if="campo.campoArray">
                    <template #header>
                        {{ campo.titulo }}
                    </template>
                    <template #body="slotProps">
                        <ul v-for="(campo, index) in slotProps.data.subAreaArray" :key="index">
                            <li>
                                {{ campo }}
                            </li>
                        </ul>
                    </template>
                </Column>
                <Column :sortable="true" :field="campo.campoHora" v-if="campo.campoHora">
                    <template #header>
                        {{ campo.titulo }}
                    </template>
                    <template #body="slotProps">
                        <span> {{ decimalToHours(slotProps.data.cargaHoraria) }}</span>
                    </template>
                </Column>
                <Column class="col-1" :sortable="true" :field="campo.campoPreco" v-if="campo.campoPreco">
                    <template #header>
                        {{ campo.titulo }}
                    </template>
                    <template #body="slotProps">
                        <span> R$ {{ formataValores(slotProps.data.custo) }}</span>
                    </template>
                </Column>
                <Column class="col-1" :sortable="true" :field="campo.campoValor" v-if="campo.campoValor">
                    <template #header>
                        {{ campo.titulo }}
                    </template>
                    <template #body="slotProps">
                        <span> R$ {{ formataValores(slotProps.data.valorTotal) }}</span>
                    </template>
                </Column>
                <Column :sortable="true" :field="campo.campoActive" v-if="campo.campoActive">
                    <template #header>
                        {{ campo.titulo }}
                    </template>
                    <template #body="slotProps">
                        <status :status="slotProps.data.active ? 'Ativo' : 'Inativo'"></status>
                    </template>
                </Column>
            </template>
        </template>
    </tabela>
</template>
<script>
import BtnInserir from '../components/BtnInserir.vue';
import SolucaoModalHistoricoIntegracao from '../solucao/SolucaoModalHistoricoIntegracao.vue';

export default {
    components: {
        BtnInserir,
        SolucaoModalHistoricoIntegracao,
    },
    name: 'TableComponent',
    props: {
        data: {
            type: Array,
        },
        campos: {
            type: Array,
        },
        inativos: {
            type: Boolean,
        },
    },
    data() {
        return {
            objeto: null,
            modalIntegracao: false,
            solucaoId: null,
            items: [
                {
                    label: 'Opções',
                    items: [
                        {
                            label: 'Atualizar',
                            icon: 'pi pi-pencil',
                            command: () => {
                                this.$emit('onEditar', this.objeto);
                            },
                        },
                        {
                            label: 'Visualizar',
                            icon: 'pi pi-search',
                            command: () => {
                                this.$emit('onVisualizar', this.objeto);
                            },
                        },
                        {
                            label: 'Alterar Situação',
                            icon: 'pi pi-arrows-h',
                            command: () => {
                                this.$emit('onAlterarSituacao', this.objeto);
                            },
                        },
                        {
                            label: 'Histórico de Alterações',
                            icon: 'pi pi-history',
                            command: () => {
                                this.$emit('onModal', this.objeto);
                            },
                        },
                        {
                            label: 'Imagens',
                            icon: 'pi pi-images',
                            command: () => {
                                this.$emit('onImagens', this.objeto);
                            },
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        inserir() {
            this.$emit('onInserir');
        },
        mostrarInativos() {
            this.inativos == true ? this.$emit('mostrarInativos', null) : this.$emit('mostrarInativos', true);
        },
        toggle(event, objeto) {
            this.objeto = objeto;
            this.$refs.menu.toggle(event);
        },
        decimalToHours(decimal) {
            const horas = Math.floor(decimal);
            const minutos = Math.round((decimal - horas) * 60);
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        },
        formataValores(valor) {
            if (!valor) {
                valor = 0;
            }
            valor = valor.toFixed(2).split('.');
            valor[0] = valor[0].split(/(?=(?:...)*$)/).join('.');
            return valor.join(',');
        },
        historicoIntegracao(id) {
            this.solucaoId = id;
            this.modalIntegracao = true;
        },
    },
};
</script>
