<template>
    <div>
        <Dialog
            header="Histórico de Alterações"
            v-model:visible="displayModal"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :modal="true"
            dismissableMask
            :maximizable="true"
        >
            <DataTable :value="alteracoes" responsiveLayout="scroll">
                <Column field="modified" header="Data"></Column>
                <Column field="modifiedBy" header="Usuário"></Column>
                <Column field="motivoEdicaoDescricao" header="Motivo"></Column>
                <Column field="justificativaAlteracao" header="Justificativa"></Column>
            </DataTable>

            <h5 class="mt-6">Histórico Inativações/Ativações</h5>
            <DataTable :value="inativadas" responsiveLayout="scroll">
                <Column field="modified" header="Data"></Column>
                <Column field="modifiedBy" header="Usuário"></Column>
                <Column field="motivoInativacaoDescricao" header="Motivo"></Column>
                <Column field="justificativaInativacao" header="Justificativa da Inativação"></Column>
            </DataTable>
        </Dialog>
    </div>
</template>

<script>
import Service from './service';
export default {
    props: {
        modal: {
            type: Boolean,
        },
        objeto: {
            type: Number,
        },
    },
    data() {
        return {
            alteracoes: null,
            inativadas: null,
            displayModal: false,
            position: 'center',
        };
    },
    mounted() {
        if (this.modal) {
            this.displayModal = true;
            this.obterAlteracoes();
            this.obterAlteracoesInativadas();
        }
    },

    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        obterAlteracoes() {
            this.$store.dispatch('addRequest');
            Service.obterAlteracoes(this.objeto).then((response) => {
                if (response?.success) {
                    this.alteracoes = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterAlteracoesInativadas() {
            this.$store.dispatch('addRequest');
            Service.obterAlteracoesInativadas(this.objeto).then((response) => {
                if (response?.success) {
                    this.inativadas = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>

<style>
</style>