<template>
    <div>
        <Dialog
            header="Histórico de Integrações"
            v-model:visible="displayModal"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :modal="true"
            dismissableMask
            :maximizable="true"
        >
            <DataTable :value="requests" responsiveLayout="scroll">
                <Column field="created" header="Data"></Column>
                <Column>
                    <template #header>
                        {{ 'Response' }}
                    </template>
                    <template #body="slotProps">
                        <ul v-for="(responseSas, index) in slotProps.data.responseSas" :key="index">
                            <li v-if="responseSas.Codigo == 0">
                                {{ 'Sucesso na Integração com o SAS - Id: ' + responseSas.IDInserido }}
                            </li>
                            <li v-else>
                                {{ responseSas.Descricao }}
                            </li>
                        </ul>
                    </template>
                </Column>
            </DataTable>
        </Dialog>
    </div>
</template>

<script>
import Service from './service';
export default {
    props: {
        modal: {
            type: Boolean,
        },
        objeto: {
            type: Number,
        },
    },
    data() {
        return {
            requests: null,
            displayModal: false,
            position: 'center',
        };
    },
    mounted() {
        if (this.modal) {
            this.displayModal = true;
            this.obterAlteracoes();
        }
    },

    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        obterAlteracoes() {
            this.$store.dispatch('addRequest');
            Service.obterHistoricoIntegracoesSas(this.objeto).then((response) => {
                if (response?.success) {
                    this.requests = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>

<style>
</style>